import { FieldType, TableSchema } from '../types';

const configTypeToText: {
  [key: string]: string;
} = {
  web: 'Wersja web',
  ios: 'Wersja iOS',
  ios_review: 'Wersja iOS w procesie weryfikacji',
};

export const configTableSchema: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'config.type',
      field: 'type',
      searchable: false,
      sortable: false,
      type: FieldType.Text,
      customValue: value => configTypeToText[value.type],
    },
    {
      name: 'config.details',
      field: 'details',
      searchable: false,
      sortable: false,
      type: FieldType.Text,
    },
    {
      name: 'config.value',
      field: 'value',
      searchable: false,
      sortable: false,
      type: FieldType.Text,
    },
  ],
};
