import React, { FC, useState, useEffect } from 'react';
import { ThemeProvider, DefaultTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getPlatformConfig, getFullPlatformConfig } from '../../../slices/platformConfigSlice';
import { theme } from '../../../config/theme';
import { RootState } from '../../../reducers';
import { Spinner } from '..';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import ApiService from '../../../services/api-service';
import { showModal } from '../../../actions/modal';

import { useApi } from '../../../services/useApi';
import { MobileAppOutdated } from '../../../components/Modals/MobileAppOutdated';
import { WebAppOutdated } from '../../../components/Modals/WebAppOutdated';

interface Props {
  children: React.ReactNode;
}

const isPWA = () => {
  const mqStandAlone = '(display-mode: standalone)';

  if ('standalone' in navigator && !!navigator.standalone) {
    return true;
  }

  if (window.matchMedia(mqStandAlone).matches) {
    return true;
  }

  return false;
};

// eslint-disable-next-line import/prefer-default-export
export const AppWrapper: FC<Props> = ({ children }) => {
  const { api } = useApi();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.details);
  const [customTheme, setCustomTheme] = useState<DefaultTheme>(theme);
  const configColors = useSelector((state: RootState) => state?.platformConfig?.colors);
  const borderRadius = useSelector((state: RootState) => state?.platformConfig?.borderRadius);

  const handleMobileVersion = async () => {
    const { version, id } = await App.getInfo();
    console.log('VERSION1', version, id);
    await ApiService.callFetch('GET', `settings/ios/${id}`, async (versions: string[]) => {
        console.log('VERSION2', versions);
      if (!versions.includes(version)) {
        dispatch(showModal(<MobileAppOutdated />));
      }
    });
  };

  const handleWebVersion = async () => {
    const currentVersion = localStorage.getItem('v') || null;

    await ApiService.callFetch('GET', `settings/web`, async (versions: string[]) => {
      if (!currentVersion || !versions.includes(currentVersion)) {
        dispatch(showModal(<WebAppOutdated newVersion={versions[0]} />));
      }
    });
  };

  const fetchAppSettings = async () => {
    if (Capacitor.isNativePlatform()) {
      handleMobileVersion();
    } else {
      handleWebVersion();
    }
  };


  useEffect(() => {
    fetchAppSettings();
    dispatch(getPlatformConfig());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      dispatch(getFullPlatformConfig());
    }
  }, [user]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchAppSettings();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (configColors) {
      setCustomTheme({
        ...theme,
        borderRadius: typeof borderRadius === 'undefined' ? theme.borderRadius : borderRadius,
        colors: { ...theme.colors, ...configColors },
      });
    }
  }, [configColors]);

  if (!configColors) return <Spinner />;

  return (
    <ThemeProvider theme={customTheme}>
      <div>{children}</div>
    </ThemeProvider>
  );
};
