import React, { useState, useEffect } from 'react';
import { ThemeProvider, DefaultTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { theme } from '../../../config/theme';
import ModalContent from '../../Common/Modal/ModalContent';
import ModalTitle from '../../Common/Modal/ModalTitle';
import ModalHeader from '../../Common/Modal/ModalHeader';
import { __ } from '../../../helpers/i18n';
import { Colors } from '../../../config/styled';
import { ButtonsContainer } from '../../Layout';
import { Button } from '../../Common';
import { Capacitor } from '@capacitor/core';

export type Props = {
  newVersion: string;
};

export const WebAppOutdated = ({ newVersion }: Props) => {
  const [customTheme, setCustomTheme] = useState<DefaultTheme>(theme);

  const configColors: Colors = useSelector((state: RootState) => state?.platformConfig?.colors);

  useEffect(() => {
    if (configColors) setCustomTheme({ ...theme, colors: { ...theme.colors, ...configColors } });
  }, [configColors]);

  const update = async () => {
    if (Capacitor.isNativePlatform()) return;
    localStorage.setItem('v', newVersion);
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        //returns installed service workers
        if (registrations.length) {
          Promise.all(registrations.map((registration) => registration.unregister())).then(
            function () {
              window.location.reload();
            },
          );
        } else {
          window.location.reload();
        }
      });
    } else {
      window.location.reload();
    }
  };

  return (
    <ThemeProvider theme={customTheme}>
      <ModalContent light>
        <ModalHeader>
          <ModalTitle title={'application.webAppOutdated'} />
        </ModalHeader>
        <p>{__('application.webAppOutdatedText')}</p>
        <ButtonsContainer>
          <Button variant="primary" onClick={update}>
            {__('application.updateVersion')}
          </Button>
        </ButtonsContainer>
      </ModalContent>
    </ThemeProvider>
  );
};
