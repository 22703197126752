import React, { FC } from 'react';
import { UserRole } from 'app-types';
import { useSelector } from 'react-redux';

import { Container } from '../../Layout';
import UserActionBox from '../UserActionBox';
import { RootState } from '../../../reducers';
import { SectionTitle, ActionsBoxesContainer } from '../../Common';
import { __ } from '../../../helpers/i18n';

// eslint-disable-next-line import/prefer-default-export
export const SuperUserActions: FC = () => {
  const tenantActionBox = [
    {
      id: 1,
      text: __('application.tenants'),
      path: '/dashboard/tenants/manage',
      bgName: 'quiz-bg',
      role: [UserRole.SuperAdmin],
      bgColor: '#31D4A3',
    },
    {
      id: 2,
      text: __('application.config'),
      path: '/dashboard/config/manage',
      bgName: 'config-bg',
      role: [UserRole.SuperAdmin],
      bgColor: '#6733A3',
    },
  ];


  const user = useSelector((state: RootState) => state.user.details);
  return (
    <section>
      <Container>
        <SectionTitle>Zarządzaj platformami</SectionTitle>
        <ActionsBoxesContainer>
          {tenantActionBox.map((box) => {
            const { path, text, id, role, bgColor } = box;
            if (user?.firstName && role?.includes(user.role)) {
              return (
                <UserActionBox
                  key={id}
                  backgroundColor={bgColor || '#31D4A3'}
                  text={text}
                  path={path}
                />
              );
            } else return null;
          })}
        </ActionsBoxesContainer>
      </Container>
    </section>
  );
};
