import React, { useState, useEffect } from 'react';
import { ThemeProvider, DefaultTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { theme } from '../../../config/theme';
import ModalContent from '../../Common/Modal/ModalContent';
import ModalTitle from '../../Common/Modal/ModalTitle';
import ModalHeader from '../../Common/Modal/ModalHeader';
import { __ } from '../../../helpers/i18n';
import { Colors } from '../../../config/styled';
export type Props = {
  login: string;
}

export const MobileAppOutdated = () => {
  const [customTheme, setCustomTheme] = useState<DefaultTheme>(theme);

  const configColors: Colors = useSelector((state: RootState) => state?.platformConfig?.colors);

  useEffect(() => {
    if (configColors) setCustomTheme({ ...theme, colors: { ...theme.colors, ...configColors } });
  }, [configColors]);

  return (
    <ThemeProvider theme={customTheme}>
      <ModalContent light>
        <ModalHeader>
          <ModalTitle
            title={'application.mobileAppOutdated'}
          />
        </ModalHeader>
        <p>{__('application.mobileAppOutdatedText')}</p>
      </ModalContent>
    </ThemeProvider>
  );
};
