export enum ApiErrorCode {
  OtherError,
  InvalidLoginData,
  UserExists,
  UserNotFound,
  FormValidationError,
  UpdateTokenExpired,
  NoSuchObjectWithThisId,
  ObjectNotUniuque,
  NotUniuqueParama,
  InvalidOldPass,
  UserIsInactive,
  UserAlreadySolvedTest,
  InvalidLang,
  FileIsTooLarge,
  ForebiddenFileFormat,
  FileIsUndefined,
  UserAlreadyVoted,
  ConnectionLost,
  TenantExists,
  CouldntGetConfiguration,
  CannotFindSegment,
  CannotFindTenantId,
  QueryTenantIsNeeded,
  GroupIsEmpty,
  FillRequiredFormFields,
  WrongEmailFormat,
  TenantIsDeactivated,
  UserIsRejected,
  AccountIsBlocked,
  WrongTelFormat,
  ContestHasParticipant,
  ContestHasStatusThatCannotBeDeleted,
  UserIsAlreadyAssigned,
  WrongQRCode,
  ParticipantCannotBeFound,
  PhoneNumberRequiresCountryCode,
  WrongCSVHeaders,
  PasswordsMustMatch,
  SMSSenderIsNotAvailable,
  WrongSignsOrLengthInSenderName,
  YouHaveToChooseReceivers,
  GroupNotFound,
  RulesAreNeeded,
  CartIsEmpty,
  NotEnoughProductsInStock,
  QueryOrParamIdIsInvalid,
  CurrencyNotFoundInTenantConfig,
  ThisCountryIsNotSupported,
  ArticleNotFound,
  QuizIsInactive,
  LoginStatusOrTokenExpired,
  TokenExpired,
  WrongPermissionOnFbPage,
  InvalidPublishTime,
  TokenIsNotSet,
  InvalidParam,
  AuthCodeInvalid,
  MethodsProviderRequired,
  InvalidAccessToken,
  InvalidInputData,
  SignUpForContestIsNotAvailable,
  YouCantDeactiveMainAction,
  MainRankingAlreadyExists,
  CannotSetAddPointsToMainRanking,
  TemporaryActionNotFound,
  AlreadyInvited,
  TelOrEmailRequired,
  IncorrectPassword,
  NotEnoughPoints,
  ProductLimitExceeded,
  AttachmentNotFound,
  AlreadyInvitedByYou,
  ThisUserCantBeInvited,
  IncorrectCSVContent,
  YouCantBuyMoreProducts,
  PasswordNeedsReset,
  NewPasswordMustBeDifferent,
}
